import React, { memo } from 'react';

const ArrowDown = ({ color = '#402379', className = '', ...rest }) => (
  <div>
    <svg
      className={className}
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M0.946065 17.57L0.943256 17.5672C0.648841 17.269 0.499999 16.871 0.499999 16.4649C0.499999 16.0603 0.649562 15.6616 0.944211 15.3588L0.944492 15.3585C1.52933 14.7567 2.4704 14.7567 3.05525 15.3585L9.64068 22.1387L10.4993 23.0228L10.4993 21.7904L10.4993 2.05803C10.4993 1.18379 11.1829 0.499999 11.9992 0.499999C12.8155 0.5 13.4991 1.18379 13.4991 2.05803L13.4991 21.7904L13.4991 23.0224L14.3577 22.1388L20.9448 15.3598C21.5296 14.7579 22.4707 14.7579 23.0555 15.3598C23.6482 15.9697 23.6482 16.9633 23.0555 17.5733L13.0576 27.8625C13.0573 27.8627 13.0571 27.8629 13.0569 27.8631C12.4684 28.4633 11.5298 28.4621 10.9454 27.8607L0.946054 17.5701L0.946065 17.57Z"
        fill={color}
      />
    </svg>
  </div>
);

export default memo(ArrowDown);
