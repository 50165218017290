import React, { memo } from 'react';

const SpotlightShape2 = ({ color = '#402379', className = '', ...rest }) => (
  <svg
    className={className}
    width="1440"
    height="640"
    viewBox="0 0 1440 640"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <g id="fill_light">
      <circle fill="#EBC373" cx="61" cy="66" r="5" />
      <circle fill="#EBC373" cx="571.1" cy="478" r="5" />
      <circle fill="#EBC373" cx="1361.1" cy="378" r="5" />
    </g>
    <g id="fill_dark">
      <circle cx="777" cy="90" r="19" fill={color} />
      <circle cx="682" cy="19" r="9" fill={color} />
      <ellipse cx="813" cy="580" rx="41" ry="40" fill={color} />
    </g>
    <g id="outlines">
      <g>
        <path
          fill="#F2F4D7"
          d="M853.2,635.7c-0.3,0-0.7,0-1-0.1c-1.1-0.3-2-1-2.5-2L717.9,376.1c-0.7-1.4-0.6-3.1,0.5-4.3
            c1-1.2,2.6-1.8,4.1-1.3L1097,470.8c1.6,0.4,2.7,1.7,2.9,3.2c0.2,1.6-0.4,3.1-1.8,4L855.4,635C854.7,635.5,853.9,635.7,853.2,635.7
            z M721.4,371.3c-0.9,0-1.7,0.4-2.3,1.1c-0.8,0.9-0.9,2.2-0.3,3.3l131.7,257.4c0.4,0.8,1.1,1.3,1.9,1.5c0.8,0.2,1.7,0.1,2.4-0.4
            l242.8-157.1c1-0.7,1.5-1.8,1.3-3c-0.2-1.2-1-2.1-2.2-2.4L722.2,371.4C722,371.3,721.7,371.3,721.4,371.3z"
        />
      </g>
    </g>
  </svg>
);

export default memo(SpotlightShape2);
