import React, { memo } from 'react';

const ClientButtonShape = ({
  color = '#EBC373',
  className = '',
  children,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    className={className}
    {...rest}
    viewBox="-2 0 104 100"
    fill="none">
    <circle cx="50" cy="50" r="50" stroke={color} strokeWidth="2" />
  </svg>
);

export default memo(ClientButtonShape);
