import React, { memo } from 'react';

const SpotlightShape1 = ({ color = '#402379', className = '', ...rest }) => (
  <svg
    className={className}
    width="1440"
    height="640"
    viewBox="0 0 1440 640"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <g id="fill_light">
      <circle fill={color} cx="1244.3" cy="359.5" r="2.5" />
      <circle fill={color} cx="78.8" cy="452" r="5" />
      <circle fill={color} cx="1068.8" cy="302" r="5" />
    </g>
    <g id="fill_dark">
      <circle fill={color} cx="1354.3" cy="684.5" r="2.5" />
      <circle fill={color} cx="190.8" cy="295" r="19" />
    </g>
    <g id="outlines">
      <g>
        <path
          fill="#F2F4D7"
          d="M371.4,458c-0.4,0.4-0.9,0.7-1.4,0.8l-210,76c-1.1,0.3-2.2,0.3-3.2-0.2c-1-0.6-1.7-1.5-2-2.5l-54.4-216.6
			c-0.4-1.6,0.1-3.2,1.4-4.1c1.3-1,2.9-1.1,4.3-0.4l264.4,140.7c1.4,0.7,2.2,2.2,2.1,3.8C372.6,456.4,372.1,457.3,371.4,458z
			 M102.1,312.4c-0.8,0.8-1,1.8-0.8,2.9l54.4,216.6c0.2,0.8,0.8,1.5,1.4,1.9c0.8,0.4,1.7,0.5,2.4,0.2l210-76c1.1-0.4,1.9-1.4,2-2.6
			s-0.5-2.3-1.6-2.9L105.6,311.8c-1.1-0.5-2.3-0.5-3.3,0.2C102.3,312.2,102.2,312.3,102.1,312.4z"
        />
      </g>
    </g>
  </svg>
);

export default memo(SpotlightShape1);
