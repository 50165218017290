import React from 'react';
import * as styles from './ContentfulClientHero.module.scss';
import { Content } from '@vccp/bernadette-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Image from '../Image/Image';
import ArrowDown from '../svgs/ArrowDown';
import ClientButtonShape from '../svgs/ClientButtonShape';

const ContentfulClientHero = ({
  backgroundColor,
  clientDescription,
  image,
  color,
  ...rest
}) => {
  const svgColor = `#${color}`;
  return (
    <div
      className={styles.clientHero}
      style={{ backgroundColor: `#${backgroundColor}` }}>
      <Content>
        <div className={styles.clientHeroLogo}>
          <Image {...image} />
        </div>
        {clientDescription && (
          <div
            style={{ color: svgColor }}
            className={styles.clientHeroDescription}>
            {renderRichText(clientDescription)}
          </div>
        )}
        <div style={{ position: 'relative' }}>
          <a href="#article">
            <ClientButtonShape
              color={svgColor}
              className={styles.clientHeroButton}></ClientButtonShape>
            <ArrowDown className={styles.clientArrow} color={svgColor} />
          </a>
        </div>
      </Content>
    </div>
  );
};

export default ContentfulClientHero;
