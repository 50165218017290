function sortProjects(project) {
  return (project || [])
    .filter((project) => project.title !== '__DUMMY_ITEM__')
    .sort((proj1, proj2) => {
      const proj1Priority = proj1.priorityProject ? 1 : 0;
      const proj2Priority = proj2.priorityProject ? 1 : 0;

      if (proj1Priority === proj2Priority) {
        return (
          new Date(proj1.publishDate).getTime() -
          new Date(proj2.publishDate).getTime()
        );
      }

      return proj1Priority - proj2Priority;
    });
}

export default sortProjects;
