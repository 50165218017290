import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import useComponentFactory from '../hooks/useComponentFactory';
import PageDataContext from '../state/PageDataContext';
import Seo from '../components/Seo/Seo';
import ContentfulClientHero from '../components/ContentfulClientHero/ContentfulClientHero';
import ProjectHighlightList from '../components/ProjectHighlightList/ProjectHighlightList';
import sortProjects from '../utils/filterProjectList';

const ProjectPageTemplate = ({ data, location, ...rest }) => {
  const pageData = data.contentfulClient;
  const sortedProjects = sortProjects(pageData.project);
  const components = useComponentFactory(pageData.components);
  return (
    <PageDataContext.Provider value={{ ...pageData, ...rest, location }}>
      <Seo
        contentTitle={pageData.clientName}
        pathname={location.pathname}
        seo={pageData.seo}
        seoFallback={pageData}
      />
      <Layout>
        <ContentfulClientHero
          backgroundColor={pageData.clientHeaderBackground}
          clientDescription={pageData.clientDescription}
          image={pageData.clientLogo}
          color={pageData.clientHeaderForeground}
        />
        <div id="article">{components}</div>
      </Layout>
    </PageDataContext.Provider>
  );
};

export const pageQuery = graphql`
  query ClientPageById($id: String!) {
    contentfulClient(id: { eq: $id }) {
      clientName
      seo {
        canonicalUrl
        description
        seoTitle
        image {
          file {
            url
          }
          title
          width
          height
        }
        hidePageFromSearchEngines
        excludeFromSitemap
      }
      clientDescription {
        raw
      }
      missionStatement {
        missionStatement
      }
      clientLogo {
        gatsbyImageData
        title
        file {
          url
        }
      }
      clientLogoThumbnail {
        gatsbyImageData
        file {
          url
        }
        title
      }
      clientAccent
      clientHeaderForeground
      clientHeaderBackground
      project {
        id
        title
        publishDate
        priorityProject
        heroImage {
          gatsbyImageData
          file {
            url
          }
          title
        }
        missionStatement {
          missionStatement
        }
        description {
          description
        }
      }
      components {
        __typename

        ... on ContentfulAward {
          ...ContentfulAwardFields
        }
        ... on ContentfulClient {
          ...ContentfulClientFields
        }
        ... on ContentfulComponentFullWidthImage {
          ...ContentfulComponentFullWidthImageFields
        }
        ... on ContentfulComponentGetInTouch {
          ...ContentfulComponentGetInTouchFields
        }

        ... on ContentfulComponentImageCarousel {
          ...ContentfulComponentImageCarouselFields
        }
        ... on ContentfulComponentPageHeader {
          ...ContentfulComponentPageHeaderFields
        }
        ... on ContentfulComponentQuote {
          ...ContentfulComponentQuoteFields
        }
        ... on ContentfulComponentShareStory {
          ...ContentfulComponentShareStoryFields
        }
        ... on ContentfulComponentProjectSpotlight {
          ...ContentfulComponentProjectSpotlightFields
        }
        ... on ContentfulComponentStats {
          ...ContentfulComponentStatsFields
        }
        ... on ContentfulComponentText {
          ...ContentfulComponentTextFields
        }
        ... on ContentfulComponentVideo {
          ...ContentfulComponentVideoFields
        }
        ... on ContentfulComponentHeading {
          ...ContentfulComponentHeadingFields
        }
        ... on ContentfulComponentContentSpotlight {
          ...ContentfulComponentContentSpotlight
        }
        ... on ContentfulComponentTwoColumnImageAndDescription {
          ...ContentfulComponentTwoColumnImageAndDescriptionFields
        }
        ... on ContentfulComponentAnimatedHeading {
          ...ContentfulComponentAnimatedHeadingFields
        }
        ... on ContentfulComponentThreeColumnContent {
          ...ContentfulComponentThreeColumnContentFields
        }
      }
    }
  }
`;

export default ProjectPageTemplate;
