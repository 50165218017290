import React, { memo } from 'react';

const SpotlightShape3 = ({ color = '#41B6E6', className = '', ...rest }) => (
  <svg
    className={className}
    width="1440"
    height="640"
    viewBox="0 0 1440 640"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <g id="fill_light">
      <ellipse cx="1228.8" cy="139.1" rx="2.5" ry="2.5" fill={color} />
      <ellipse cx="55.7" cy="231.6" rx="5" ry="5" fill={color} />
      <ellipse cx="1052.1" cy="81.6" rx="5" ry="5" fill={color} />
      <ellipse cx="232.9" cy="75" rx="56.4" ry="56" fill={color} />
    </g>
    <g id="fill_dark">
      <path
        fill="#EBC373"
        d="M245.4,634.2c-1.1,2.1-3.7,2.8-5.7,1.5l-124.7-80.3c-2.9-1.9-2.2-6.3,1.1-7.2l192.9-51.7
          c3.4-0.9,6.2,2.6,4.6,5.7L245.4,634.2z"
      />
    </g>
    <g id="outlines">
      <g>
        <path
          fill="#F2F4D7"
          d="M326.9,638.2c-0.8,0-1.5-0.2-2.2-0.6l0,0l-305.9-197c-1.3-0.9-2-2.4-1.8-4c0.2-1.6,1.4-2.8,2.9-3.3
            l473.3-126.8c1.6-0.4,3.1,0.1,4.1,1.4c1,1.3,1.2,2.9,0.4,4.3L330.4,636c-0.5,1-1.4,1.7-2.5,2C327.5,638.1,327.2,638.2,326.9,638.2
            z M325.2,636.7c0.7,0.5,1.6,0.6,2.4,0.4c0.8-0.2,1.5-0.8,1.9-1.5L497,311.7c0.6-1.1,0.4-2.3-0.3-3.3c-0.8-0.9-1.9-1.3-3.1-1
            L20.2,434.2c-1.2,0.3-2,1.2-2.2,2.4c-0.2,1.2,0.3,2.3,1.3,3L325.2,636.7z"
        />
      </g>
    </g>
  </svg>
);

export default memo(SpotlightShape3);
