import React, { memo } from 'react';
import cx from 'classnames';
import { Spotlight as SpotlightComponent } from '@vccp/bernadette-components';
import * as styles from './ProjectHighlight.module.scss';
import SpotlightShape1 from '../svgs/SpotlightShape1';
import SpotlightShape2 from '../svgs/SpotlightShape2';
import SpotlightShape3 from '../svgs/SpotlightShape3';
import ParallaxWithBgr from '../ParallaxWithBgr/ParallaxWithBgr';
import Image from '../Image/Image';
import MoreLink from '../MoreLink/MoreLink';

const shapes = {
  'shape-1': SpotlightShape1,
  'shape-2': SpotlightShape2,
  'shape-3': SpotlightShape3,
};

const defaultShape = Object.keys(shapes)[0];

const ProjectHighlight = ({
  id,
  reverse,
  accentColor = '',
  bgrType = defaultShape,
  image,
  ctaText,
  ...rest
}) => {
  const Shape = shapes[bgrType] || shapes[defaultShape];
  return (
    <ParallaxWithBgr
      background={
        <Shape
          className={cx(styles.bgr, reverse && styles.reverse)}
          color="#402379"
        />
      }>
      <SpotlightComponent
        className={styles.content}
        reverse={reverse}
        description=""
        cta={<MoreLink pageId={id}>{ctaText}</MoreLink>}
        accentColor={accentColor}
        {...rest}>
        <div className={styles.spotlightImage}>
          <Image {...image} />
        </div>
      </SpotlightComponent>
    </ParallaxWithBgr>
  );
};

export default memo(ProjectHighlight);
